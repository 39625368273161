import { Directive, ElementRef, Renderer, Input } from "@angular/core";

@Directive({
    selector: '[disabledIf]'
})



export class DisabledIfDirective {

    @Input() set disabledIf(isDisabled: boolean) {
        this.refreshState(isDisabled);
    }
    constructor(private _element: ElementRef, private renderer: Renderer) {
    }
    refreshState(isdisabled: boolean): void {
        if (isdisabled === undefined) {
            return;
        }

        if (isdisabled) {
            this.renderer.setElementClass(this._element.nativeElement, 'disabled-div', true);
        } else {
            this.renderer.setElementClass(this._element.nativeElement, 'disabled-div', false);
        }
    }
}
