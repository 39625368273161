import { Component, OnInit, Injector, Input, EventEmitter, Output } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NgForm } from '@angular/forms';
import { CreateMollieCreditPaymentInput, OperationBancairesServiceProxy, MollieCreateMandateInput, CreateMollieDebitPaymentInput, API_BASE_URL } from '@shared/service-proxies/service-proxies';
import { APP_BASE_HREF } from '@angular/common';
import { getBaseHref } from 'root.module';
import { AppConsts } from '@shared/AppConsts';

interface PayementModel{
    id:number;
    typecontratId:number;
    membrereference:string;
}

interface DebitPaymentModel{
    name:string;
    accountNumber:string;
    accountBic:string;
    userConsent: boolean;
}


@Component({
    selector: 'mollie-payment',
    templateUrl: './mollie-payment.component.html',
})
export class MolliePaymentComponent extends AppComponentBase implements OnInit {

    @Input() firstPayementAmount : number;
    @Input() typeContratId : number;
    @Input() userid : number;
    @Input() referenceMembre : string;
    @Output() formSave: EventEmitter<any> = new EventEmitter<any>(); // 
    // @Input () parentModal;


    method: string= ' ';
    creditInput: CreateMollieCreditPaymentInput;
    debitInput: CreateMollieDebitPaymentInput;
    mandateModel: MollieCreateMandateInput;
    paiementLoding: boolean;
    debitpaiementLoding: boolean;
    creditpaiementLoding: boolean;

    constructor(
        injector: Injector,
        private _operationBancairesService : OperationBancairesServiceProxy,

    ) {
        super(injector);
        this.creditInput = new CreateMollieCreditPaymentInput();
        this.mandateModel = new MollieCreateMandateInput();
        this.debitInput = new CreateMollieDebitPaymentInput();

    }

    ngOnInit(): void {
        console.log(AppConsts.appBaseUrl);
        console.log("server url");
        console.log(API_BASE_URL);
     }

    onAcceptCondition(event:any){

    }

    toggleMethod(method:string){
        if(method){
            this.method = method;
        }
    }

    createMollieCreditPayment(){
        this.paiementLoding = true;
        this.creditpaiementLoding = true;
        this.creditInput.amount = "10.00";
        this.creditInput.userId = this.userid;
        this.creditInput.redirectUrl = AppConsts.appBaseUrl;
        this.creditInput.subscriptionAmount = this.getDecimalValueAsString(AppConsts.TKFL_FORMULES[this.typeContratId]);
        this.creditInput.subscriptionDescription = this.referenceMembre+'-'+AppConsts.TKFL_FORMULES_NAMES[this.typeContratId];
        this.debitInput.webHookUrl = "https://digitar-digiapps-web-host.conveyor.cloud/mollie/webhooks";
        // this.creditInput.webHookUrl = AppConsts.remoteServiceBaseUrl+"/mollie/webhooks";

        this._operationBancairesService.createMollieCreditPayment(this.creditInput)
        .subscribe(res=>{
            if(res.chckoutUrl){
                console.log(res);
                window.location.href = res.chckoutUrl;
                this.paiementLoding = false;
                this.creditpaiementLoding = false;
            }
            
        });
    }
    createMollieDebitPayment(){
        // console.log(this.mandateModel);
        this.paiementLoding = true;
        this.debitpaiementLoding = true;

        this.debitInput.amount = "10.00";
        this.debitInput.userId = this.userid;
        this.debitInput.redirectUrl = AppConsts.appBaseUrl;
        this.debitInput.subscriptionAmount = this.getDecimalValueAsString(AppConsts.TKFL_FORMULES[this.typeContratId]);
        this.debitInput.subscriptionDescription = this.referenceMembre+'-'+AppConsts.TKFL_FORMULES_NAMES[this.typeContratId];
        this.debitInput.mandateInput = this.mandateModel;
        this.debitInput.webHookUrl = "https://digitar-digiapps-web-host.conveyor.cloud/mollie/webhooks";
        // this.creditInput.webHookUrl = AppConsts.remoteServiceBaseUrl+"/mollie/webhooks";
        this._operationBancairesService.createMollieDebitPayment(this.debitInput)
        .subscribe(res=>{
            console.log(res);
            this.paiementLoding = false;
            this.debitpaiementLoding = false;

            this.formSave.emit(true);
        });
    }

    getDecimalValueAsString(amount :number){
        if(amount){
            return amount.toFixed(2).toString();
        }
    }

}
