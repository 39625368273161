import { Injector, ElementRef, Component, OnInit, AfterViewInit, ViewChild, HostBinding } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { MenuAsideOffcanvasDirective } from '@metronic/app/core/directives/menu-aside-offcanvas.directive';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { Observable, from } from 'rxjs';
import { TenantSettingsServiceProxy, TenantSettingsEditDto } from '@shared/service-proxies/service-proxies';

@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()]
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit, AfterViewInit {

    @ViewChild('mHeader') mHeader: ElementRef;
    @ViewChild('mAsideLeft') mAsideLeft: ElementRef;
    @HostBinding('attr.mMenuAsideOffcanvas') mMenuAsideOffcanvas: MenuAsideOffcanvasDirective;

    isTimeTraveled:boolean;
    ct:string='';

    constructor(
        injector: Injector,
        private router: Router,
        private layoutRefService: LayoutRefService,
        private _tenantSettingsService:TenantSettingsServiceProxy
    ) {
        super(injector);
        this.isTimeTraveled = AppConsts.isTimeTraveled;
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }

    getTimeTravelValue(){
        if(this.isGrantedAny('Pages.Tenant.Dashboard')){
            this._tenantSettingsService.getAllSettings()
                .subscribe((result: TenantSettingsEditDto) => {
                    if(result.otherSettings.currentTime.length>0){
                        this.ct=result.otherSettings.currentTime;
                        this.isTimeTraveled=true;
                        
                    }else{
                        this.isTimeTraveled = false;
                    }
                });
        }else{
            this.ct='';
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.mMenuAsideOffcanvas = new MenuAsideOffcanvasDirective(this.mAsideLeft, this.router);
            this.mMenuAsideOffcanvas.ngAfterViewInit();
        });
        this.getTimeTravelValue();
        this.layoutRefService.addElement('header', this.mHeader.nativeElement);
    }
}
