import { Directive, ElementRef, Renderer, Input } from "@angular/core";

@Directive({
    selector: '[dsLoadingIf]'
})



export class DsLoadingIfDirective {

    @Input() set dsLoadingIf(isLoading: boolean) {
        this.refreshState(isLoading);
    }
    constructor(private _element: ElementRef, private renderer: Renderer) {
    }
    refreshState(isLoading: boolean): void {
        if (isLoading === undefined) {
            return;
        }

        if (isLoading) {
            this.renderer.setElementClass(this._element.nativeElement, 'dsPlaceholder', true);
            // this.renderer.setElementStyle(this._element.nativeElement, 'visibility','none');
        } else {
            this.renderer.setElementClass(this._element.nativeElement, 'dsPlaceholder', false);
        }
    }
}
