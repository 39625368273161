import * as moment from 'moment';

export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    // AI
    static membreMaxAge = 65;
    static membreMinAge = 18;
    static memberMaxDateNaissance: moment.Moment = moment().add(-(AppConsts.membreMinAge), 'years');
    static memberMinDateNaissance: moment.Moment = moment().add(-(AppConsts.membreMaxAge), 'years');
    static memberMinDateContrat: moment.Moment = moment();
    static DateNaissanceMaxError = 'Vous n\'êtes pas autorisé  à s\'inscrire car vous avez plus que '+AppConsts.membreMaxAge+' ans';
    static DateNaissanceMinError = 'Vous n\'êtes pas autorisé  à s\'inscrire car vous avez moins que '+AppConsts.membreMinAge+' ans';
    static DateNaissanceCjtMaxError = 'Les personnes ayant plus que '+AppConsts.membreMaxAge+' ans ne peuvent pas être couvert.';
    static DateNaissanceCjtMinError = 'Les personnes ayant moins que '+AppConsts.membreMinAge+' ans ne peuvent pas être couvert.';
    static currentUserId :number;
    static isTimeTraveled :boolean;
    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Digiapps'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };
    // inscription / maghreb famille / maghreb individue / afrique famille / afrique individue
    static TKFL_FORMULES = [10,50,25,70,35];
    static TKFL_FORMULES_NAMES = ['AUCUN','MAGHREB - FAMILLE','MAGHREB - INDIVIDU','AFRIQUE - FAMILLE','AFRIQUE - INDIVIDU'];
    static FeedBackKeyOptions = new Map([
        ['addedCount', 'Membres Ajouté'],
        ['invalidCount','Membres Non Valide'],
        ['ignoredCount','Membres Annulé '],
        ['addedOBCount','Transactions Ajouté'],
        ['ignoredOBCount','Transactions Anullé'],
        ['invalidOBCount','Transactions Non Valide'],
        ['orphelinOBCount','Transactions Orphelin'],
        ]); 
    static FeedBackCssByKey = new Map([
        ['addedCount', 'import-added'],
        ['addedOBCount', 'import-added'],
        ['invalidCount','import-invalid'], 
        ['invalidOBCount','import-invalid'], 
        ['ignoredCount','import-ignored'],
        ['ignoredOBCount','import-ignored'],
        ['orphelinOBCount','import-orphelin'],
    ]); 
}