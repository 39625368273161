import { Component, ViewChild, Injector, Output, EventEmitter, ViewEncapsulation, AfterViewInit, ChangeDetectorRef, OnInit, Input, HostListener} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { OperationBancairesServiceProxy, CreateOrEditOperationBancaireDto, StripePaymentServiceProxy, PaymentServiceProxy, SubscriptionPaymentDto, SubscriptionPaymentGatewayType, SubscriptionStartType, StripeConfigurationDto, StripeConfirmPaymentInput, StripePaiementInput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { MembreLookupTableModalComponent } from '../membres/membre-lookup-table-modal.component';
import { environment } from 'environments/environment';
import { ReactiveFormsModule, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantRegistrationHelperService } from '@account/register/tenant-registration-helper.service';
import { ScriptLoaderService } from '@shared/utils/script-loader.service';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import { AppConsts } from '@shared/AppConsts';
// import {createMollieClient} from '@mollie/api-client';




@Component({
    selector: 'createOrEditPaiementModal',
    templateUrl: './create-or-edit-paiement-modal.component.html',
    encapsulation:ViewEncapsulation.None
})
export class CreateOrEditPaiementModalComponent extends AppComponentBase implements OnInit {
    
    @ViewChild('createOrEditModal') modal: ModalDirective;
    
    @Input() amount;
    @Input() description;
    @Input() userId;
    @Input() typeContratId;
    @Input() referenceMembre;

    @Input() fromInscriptionForm;
    /**
     * AI NOTEs about modalSAve
     * currently modalSAve user booeal value paiement passed ?
     * TODO: change it to fixed modal that hols data + event +message to the user
     */
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>(); // 
    stripForm: FormGroup;
    active = false;
    saving = false;

    operationBancaire: CreateOrEditOperationBancaireDto = new CreateOrEditOperationBancaireDto();

    membreNom = '';

    // stripe fields::begins
    cardNumber: any;
    cardExpiry: any;
    cardCvc: any;
    // cardHandler = this.onChange.bind(this);
    stripetoken :any;
    stripeCardNumberError: any;
    stripeCardExpiryError: any;
    stripeCardCvcError: any;
    stripe: stripe.Stripe;
    //stripe fields ::ends
    // checkout fields ::start
    handler: StripeCheckoutHandler;
    loading = false;
    confirmation: any;
    // checkout ends

    subscriptionPayment: SubscriptionPaymentDto;
    stripeIsLoading = true;
    subscriptionPaymentGateway = SubscriptionPaymentGatewayType;
    subscriptionStartType = SubscriptionStartType;

    paymentId;
    successCallbackUrl;
    errorCallbackUrl;
    redirectUrl = '';

    transactionToCreate: CreateOrEditOperationBancaireDto;
    molliecostumerId :  string;


    constructor(
        injector: Injector,
        private _operationBancairesServiceProxy: OperationBancairesServiceProxy,
        private cd: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private _stripePaymentAppService: StripePaymentServiceProxy,
        private _paymentAppService: PaymentServiceProxy,
        private _router: Router,
        // private _tenantRegistrationHelper: TenantRegistrationHelperService
    ) {
        super(injector);
        this.transactionToCreate = new CreateOrEditOperationBancaireDto();
    }

    ngOnInit(): void {
        this.paymentId = this._activatedRoute.snapshot.queryParams['paymentId'];
        this.redirectUrl = this._activatedRoute.snapshot.queryParams['redirectUrl'];
        let isUpgrade = this._activatedRoute.snapshot.queryParams['isUpgrade'];

        // new ScriptLoaderService().load('https://checkout.stripe.com/checkout.js').then(() => {

        //     //AI
        //     this._stripePaymentAppService.getConfiguration()
        //                     .subscribe((config: StripeConfigurationDto) => {
        //                         this.prepareStripeButton(config.publishableKey);
        //                         this.stripeIsLoading = false;
        //                     });
        // });
    }

    prepareStripeButton(stripeKey: string): void {
        let handler = StripeCheckout.configure({
            key: stripeKey,
            locale: 'auto',
            currency: this.appSession.application.currency,
            token: token => {
                abp.ui.setBusy();
                let input = new StripePaiementInput();
                // input.paymentId = this.paymentId;
                input.amount = this.amount;
                input.description = this.description;
                input.stripeToken = token.id;
                this.transactionToCreate.communication = this.description;
                if(this.appSession.userId){
                    this.userId = this.appSession.userId;
                }
                // this.transactionToCreate.userId = AppConsts.currentUserId;
                this.transactionToCreate.userId = this.userId;
                this.transactionToCreate.description = token.id;
                this.transactionToCreate.dateTransaction = moment();
                this.transactionToCreate.montant = this.amount;
                input.transactionObject = this.transactionToCreate;

                this._operationBancairesServiceProxy.confirmPayment(input).subscribe(() => { // where paiement get passed
                    abp.ui.clearBusy();
                    this.close();
                    this.modalSave.emit(true);
                    this.notify.success('paiement succeed');
                });
            }
        });

        document.getElementById('stripe-checkout').addEventListener('click', e => {
            handler.open({
                name: 'ETTAKAFUL',
                email: 'support@ettakaful.be',
                // description: this.description,
                // amount: this.amount * 100
                description: this.description,
                amount: this.amount * 100
            });
            e.preventDefault();
        });

        window.addEventListener('popstate', () => {
            handler.close();
        });
    }
    

    
    //stripe methods ::ends
    show(operationBancaireId?: string): void {

        if (!operationBancaireId) {
            this.operationBancaire = new CreateOrEditOperationBancaireDto();
            this.operationBancaire.id = operationBancaireId;
            this.operationBancaire.dateTransaction = moment().startOf('day');
            this.membreNom = '';
            

            this.active = true;
            this.modal.show();
        } else {
            this._operationBancairesServiceProxy.getOperationBancaireForEdit(operationBancaireId).subscribe(result => {
                this.operationBancaire = result.operationBancaire;
                this.membreNom = result.membreNom;
                this.active = true;
                this.modal.show();
            });
        }
    }

    save(): void {
            this.saving = true;
            this._operationBancairesServiceProxy.createOrEdit(this.operationBancaire)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.modalSave.emit(true);
                this.close();
             });
    }


    close(): void {

        this.active = false;
        this.modalSave.emit(false);
        this.modal.hide();
    }

    //mollie code
    // create mollie costumer
    mollie_createCostumer(){
        // this._operationBancairesServiceProxy.createMollieCostumer()
        // .subscribe(res=>{
        //     console.log("mollie costumer created");
        // });

        this._operationBancairesServiceProxy.createMollieCostumer()
        .subscribe(res=>{
            // this.molliecostumerId = res.id;
            console.log(res);

        });
    }

    // mollie_createFirstPayement(){
    //     this._operationBancairesServiceProxy.createMollieFirstPayement("cst_tA3dg6ApxB")
    //     .subscribe(res=>{
    //         console.log(res);
    //     });
    // }


    mpllie_createMandate(){
        this._operationBancairesServiceProxy.createMollieMandate("cst_Vc4P463zPD")
        .subscribe(res=>{{
            console.log(res);
        }})
    }

    mollie_createSubscription(){
        this._operationBancairesServiceProxy.createMollieSubscription("cst_Vc4P463zPD")
        .subscribe(res=>{
            console.log(res);
        });
    }

}
